
import { defineComponent, ref, computed, reactive, watch, getCurrentInstance } from "vue"
import moment from 'moment'
import { Visor } from "@/models/visors";
import { Reservation, timeLeft } from "@/models/reservations";
import { Contract } from "@/models/contracts";
import { Posting } from "@/models/postings";
import { Household } from "@/models/households";
import { CareReceiver } from "@/models/care_receivers";
import { Suggestion } from "@/models/suggestions";
import { Address as AddressModel } from "@/models/addresses";
import { Segment } from "@/models/segments";
import { Lead } from "@/models/leads";
import { User } from "@/models/users";
import { FileMetaData as FMDModel } from "@/models/file_meta_datas";
import { CareGiverInstance } from "@/models/care_giver_instances";
import { CareStay } from "@/models/care_stays";
import { ResourceObject, RelationshipsWithData, ResourceIdentifierObject } from '@/models/jsonapi'
import BKFormEdit from "@/components/shared/BKFormEdit.vue"
import Address from "@/components/provider/Address.vue"
import BkSuggestions from "@/components/provider/BKSuggestions.vue"
import CareStayTable from "@/components/provider/CareStayTable.vue"
import Tickets from "@/components/shared/Tickets.vue"
import TicketEdit from "@/components/shared/TicketEdit.vue"
import Logs from "@/components/shared/Logs.vue";
import FileMetaData from "@/components/shared/FileMetaData.vue"
import IconsBarCareReceiver from "@/components/shared/IconsBarCareReceiver.vue";
import CareReceiverProgressBar from "@/components/shared/CareReceiverProgressBar.vue";
import IconsBarCareGiverRequirements from "@/components/shared/IconsBarCareGiverRequirements.vue";
import SchemaForm from "@/components/shared/form/SchemaForm.vue"
import Search from "@/components/shared/Search.vue";
import { fullName, fileSize, resourceUrl } from "@/utils/dataExtractors"
import { apiDataMorpher, extractRelationshipIds, extractIncludedTypes } from "@/utils/apiDataHelper"
import { CrPage } from "@/models/care_receivers";
import { newTicketAction } from "@/view_scripts/title_action_new_ticket";
import { confirm, prompt } from '@/utils/interactionModals'
import { provideRefreshData } from "@/utils/refreshData"
import { schema, providerUiSchema } from "@/view_scripts/form_schemas/visor"
import { fetchRelated } from "@/utils/apiDataHelper"
import { stringSort } from "@/utils/helper"

interface Props {
  id: string
}

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    BKFormEdit,
    Tickets,
    BkSuggestions,
    TicketEdit,
    Logs,
    FileMetaData,
    CareReceiverProgressBar,
    IconsBarCareReceiver,
    IconsBarCareGiverRequirements,
    Search,
    SchemaForm,
    CareStayTable,
    Address
  },
  setup(props: Props) {
    // const visor = ref<ResourceObject<string, Visor>>()
    const root = getCurrentInstance().proxy
    const seller = ref<ResourceObject<string, User>>()
    const visor = ref<ResourceObject<string, Visor>>()
    const posting = ref<ResourceObject<string, Posting>>()
    const reservations = ref<ResourceObject<string, Reservation>[]>([])
    const household = ref<ResourceObject<string, Household>>()
    const householdAddress = ref<ResourceObject<string, AddressModel>>()
    const contractAddress = ref<ResourceObject<string, AddressModel>>()
    const careStays = ref<ResourceObject<string, CareStay>[]>([])
    const careReceivers = ref<ResourceObject<string, CareReceiver>[]>([])
    const resourceIndex = ref<{ [key: string]: any }>({})
    const fmdIndex = ref<{ [key: string]: ResourceObject<string, CareReceiver> }>({})
    const allSuggestions = ref<ResourceObject<string, Suggestion>[]>([])
    // const contracts = ref<ResourceObject<string, Contract>[]>([])
    const contract = ref<ResourceObject<string, Contract> | null>(null)
    const emergencyPhone = ref<string>('na')
    // const suggestionIds = computed<ResourceObject<string, Suggestion>[]>(() => {
    //   return allSuggestions.value.reduce((res: any, sug: any) => {
    //     if (!sug.relationships?.contract?.data?.id) {
    //       res.push(sug.id)
    //     }
    //     return res
    //   }, [])
    // })
    const ticketIds = computed<string[]>(() => visor.value ? extractRelationshipIds({ data: [visor.value] }, 'tickets') : [])
    const showLoading = ref<Boolean>(false)
    // watch(visor, async (newValue) => {
    //   const postingId = extractRelationshipIds({ data: [newValue] }, 'posting').join(',')
    //   root.$store.dispatch('postings/get', { id: postingId })
    //   const suggestionIds = extractRelationshipIds({ data: [newValue] }, 'suggestions').join(',')
    //   root.$store.dispatch('suggestions/load', { 'filter[id]': suggestionIds, include: 'segment' })
    //   seller.value = await root.$store.dispatch('users/get_seller', newValue)
    // })
    // watch(posting, async (newValue) => {
    //   const householdId = extractRelationshipIds({ data: [newValue] }, 'household').join(',')
    //   await root.$store.dispatch('households/get', { id: householdId, queryParams: { include: 'care_receivers' } })
    // })
    // const household = computed<ResourceObject<string, Household>>(() => root.$store.state.households.element)
    // watch(household, (newValue) => {
    //   const careReceiverIds = extractRelationshipIds({ data: [newValue] }, 'care_receivers').join(',')
    //   const crQuery = { 'filter[id]': careReceiverIds, include: 'file_meta_datas'}
    //   root.$store.dispatch('care_receivers/load', crQuery)
    // })
    // const careReceivers = computed<ResourceObject<string, CareReceiver>[]>(() => root.$store.state.care_receivers.data)
    // const suggestions = computed<ResourceObject<string, Suggestion>[]>(() => {
    //   return root.$store.state.suggestions.data.filter((sug: any) => !sug.relationships?.contract?.data?.id)
    // })
    // watch(suggestions, (newValue) => {
    //   const cgiIds = newValue.map((sug) => sug?.attributes?.segment?.attributes?.careGiverInstanceId)
    //   const segmentIds = extractRelationshipIds({ data: newValue }, 'segment').join(',')
    //   root.$store.dispatch('segments/load', { 'filter[id]': segmentIds })
    //   root.$store.dispatch('care_giver_instances/load', { 'filter[id]': cgiIds.join(',') })
    // })
    // const segments = computed<ResourceObject<string, Segment>[]>(() => root.$store.state.segments.data)
    // const careGiverInstances = computed<ResourceObject<string, CareGiverInstance>[]>(() => root.$store.state.care_giver_instances.data)

    // const foundCGI = ref<ResourceObject<string, CareGiverInstance>>()
    const newTicketModal = ref()
    const newSuggestionModal = ref()
    const newReservationModal = ref()
    const editModal = ref()
    const careReceiverForm = ref<CrPage[]>([])
    const visorToEdit = ref<ResourceObject<string, Visor>>()
    const reservedUntil = ref({
      date: moment().add(1, 'days').format('YYYY-M-D'),
      time: '12:00:00',
      min: moment().add(1, 'days').toDate(),
      max: moment().add(5, 'days').toDate()
    })

    const refreshData = async () => {
      const visorQuery = {
        'filter[id]': props.id,
        'include': [
          'posting.household.lead.seller.address.phones',
          'posting.household.care_receivers.file_meta_datas',
          'posting.household.address',
          'posting.household.address.emails',
          'posting.household.address.phones',
          'posting.household.contract_address',
          'posting.household.contract_address.phones',
          'posting.household.contract_address.emails',
          'contract,care_stays.segments.careGiverInstance',
          'care_stays.care_giver_instance.care_giver',
          'care_stays.contract',
          'reservations'
        ].join(','),
        'fields[households]': 'designation',
        'fields[care_receivers]': 'all',
        'fields[file_meta_datas]': 'sysId,contentType,filename,length',
        'fields[sellers]': 'firstName,lastName,mobilePhone',
        'fields[contracts]': 'archived',
        'fields[care_stays]': 'all',
        'fields[care_givers]': 'firstName,lastName',
        'fields[addresses]': 'all',
        'fields[emails]': 'all',
        'fields[phones]': 'all',
        'fields[contract_addresses]': 'all',
        'fields[reservations]': 'all'
        // 'relationships[suggestions]': 'contract',
      }
      root.$store.dispatch('visors/loadv2', visorQuery).then((visorResponse) => {
        careReceivers.value = []
        allSuggestions.value = []
        careStays.value = []
        // console.log('vr: ',visorResponse.included)
        visor.value = visorResponse.data.data[0]
        visorResponse.data.included.forEach((resource: any) => {
          resourceIndex.value[resource.id] = resource
          const emails = visorResponse.data.included.filter((e) => { return e.type === 'emails' })
          const phones = visorResponse.data.included.filter((e) => { return e.type === 'phones' })
          const type = resource.type
          if (type === 'postings') {
            posting.value = resource
          } else if (type === 'sellers') {
            seller.value = resource
          } else if (type === 'households') {
            household.value = resource
          } else if (type === 'file_meta_datas' && resource.attributes?.sysId === 'cr_assessment_sheet') {
            fmdIndex.value[resource.id] = resource
          } else if (type === 'care_receivers' && resource.attributes?.active) {
            careReceivers.value.push(resource)
          } else if (type === 'suggestions') {
            allSuggestions.value.push(resource)
          } else if (type === 'contracts') {
            contract.value = resource
          } else if (type === 'care_stays') {
            careStays.value.push(resource)
          } else if (type === 'addresses') {
            if (resource.attributes.addressableType == 'Household') {
              const emailIds = resource.relationships?.emails?.data?.map(e => e.id)
              const phoneIds = resource.relationships?.phones?.data?.map(e => e.id)
              householdAddress.value = { ...resource, included: {
                emails: emails?.filter(e => emailIds?.includes(e.id)),
                phones: phones?.filter(p => phoneIds?.includes(p.id)),
              }}
            }
          } else if (type === 'contract_addresses') {
            const emailIds = resource.relationships?.emails?.data?.map(e => e.id)
            const phoneIds = resource.relationships?.phones?.data?.map(e => e.id)
            contractAddress.value = { ...resource, included: {
                emails: emails?.filter(e => emailIds?.includes(e.id)),
                phones: phones?.filter(p => phoneIds?.includes(p.id)),
              }}
          } else if (type === 'reservations') {
            reservations.value.push(resource)
          } else if (type === 'phones' && resource.attributes.sysId == 'pn_aircall') {
            emergencyPhone.value = resource.attributes.number
          } else if (type === 'phones') {
            if (resource.attributes.addressableType === 'Household') {
            } else { console.log('e;se', resource.attributes.addressableType) }
          }
        })
      })
      await root.$store.dispatch('care_receivers/get_pages')
      careReceiverForm.value = root.$store.state.care_receivers.pages
      }

    const careStayTableItems = computed(() => {
      const tableItems = careStays.value.reduce((res: any[], cs: any) => {
        const cg = fetchRelated(cs, 'careGiverInstance.careGiver', resourceIndex.value)
        cs.meta = {
          cGName: fullName(cg),
          contractId: cs?.relationships?.contract?.data?.id,
          visorId: visor.value?.id
        }
        res.push(cs)
        return res
      }, [])
      return stringSort(tableItems, (s) => s.attributes.billStart, false)
    })

    refreshData()
    provideRefreshData(refreshData)

    const submitTicket = () => {
      newTicketModal.value.hide()
      refreshData()
    }

    const bkSubmitted = async (bkSuggestData: any) => {
      bkSuggestData.usecase = 'new_suggestion'
      if (visor?.value?.id) {
        try {
          showLoading.value = true
          await root.$store.dispatch('visors/edit', { id: visor.value.id, body: bkSuggestData })
          newSuggestionModal.value.hide()
          refreshData()
        } catch (e) {
          console.log('bk_suggest error: ', e)
        } finally {
          showLoading.value = false
        }
      }
    }

    const saveVisor = async () => {
      if (visorToEdit?.value?.id) {
        try {
          showLoading.value = true
          await root.$store.dispatch('visors/edit', { id: visorToEdit.value.id, body: visorToEdit.value })
          editModal.value.hide()
        } catch (e) {
          console.log('bk_suggest error: ', e)
        } finally {
          refreshData()
          showLoading.value = false
        }
      }
    }

    const displayFile = (fileMetaData: ResourceObject<string, FMDModel>, shouldDownload: boolean = false) => {
      root.$store.dispatch('file_meta_datas/display_file', { fmd: fileMetaData, shouldDownload: shouldDownload })
    }

    // const desiredArrival = (cr:any) => {
    //   let d = cr.attributes.createdAt
    //   if (cr.attributes.desiredArrival == 'konkretes Wunschdatum') {
    //     return cr.attributes.arrivalDate
    //   } else if (cr.attributes.desiredArrival == 'in 1 Woche') {
    //     d.setDate(d.getDate()+7)
    //   } else if (cr.attributes.desiredArrival == 'in 2 Woche') {
    //     d.setDate(d.getDate()+14)
    //   } else {return 'Unbekannt'}
    // }

    const isReservationActive = (reservation: any) => {
      const isExpired = timeLeft(reservation, false) === '0'
      return !(isExpired || reservation.attributes.withdrawn || reservation.attributes.cancelled)
    }

    const hasActiveReservation = computed(() => {
      if (!reservations.value.length) return false
      return reservations.value.some((r: any) => isReservationActive(r))
    })

    const titleActions = computed(() => {
      const contractPresent = visor.value?.relationships?.contract?.data?.id
      const activeReservationOrContractPresent = hasActiveReservation.value || contractPresent
      return [
        {
          title: 'Bearbeiten',
          icon: 'pencil-alt',
          tooltip: 'Stelle bearbeiten',
          onClick: () => {
            visorToEdit.value = JSON.parse(JSON.stringify(visor.value))
            editModal.value.show()
          }
        },
        newTicketAction(newTicketModal),
        {
          title: 'cgi_suggest',
          icon: activeReservationOrContractPresent ? ['fas', 'user-nurse'] : 'exclamation-triangle',
          tooltip: !contractPresent ? 'Betreuungskraft vorschlagen' : 'Wechselkraft vorschlagen',
          onClick: () => {
            if (activeReservationOrContractPresent) {
              newSuggestionModal.value.show()
            } else {
              prompt(root, "Keine aktive Reservierung für dieses Visier gefunden.")
            }
          }
        },
        {
          title: 'Erstellen',
          icon: ['fas', 'calendar-plus'],
          tooltip: activeReservationOrContractPresent ? 'Aktive Reservierung vorhanden' : 'Reservierung erstellen',
          onClick: () => {
            if (activeReservationOrContractPresent) {
              prompt(root, "Neue Reservierung kann nicht erstellt werden, da eine aktive Reservierung verfügbar ist oder ein Vertrag vorliegt")
            } else {
              newReservationModal.value.show()
            }
          }
        },
      ]
    })

    const createReservation = () => {
      showLoading.value = true
      const id = posting.value.id
      const reserved_until = moment(`${reservedUntil.value.date} ${reservedUntil.value.time}`).toISOString(true)
      root.$store.dispatch('reservations/create_v2', { id, reservedUntil: reserved_until }).then(() => {
        refreshData()
        showLoading.value = false
      })
    }

    const homeLink = computed(() => {
      if (visor.value && visor.value.attributes?.externalId) {
        const rels = visor.value.relationships?.agency as RelationshipsWithData
        const agencyId = (rels.data as ResourceIdentifierObject)?.id
        if (agencyId == "5b5983ba4c73c6a2dc5348b0") {
          return `https://anfrage.aterima.pl/questionnaires/${visor.value.attributes.externalId}`
        }
      } else { return false }
    })

    return {
      showLoading,
      ticketIds,
      visor,
      posting,
      // suggestionIds,
      // segments,
      // careGiverInstances,
      household,
      careReceivers,
      fmdIndex,
      titleActions,
      submitTicket,
      bkSubmitted,
      refreshData,
      newSuggestionModal,
      newTicketModal,
      fullName,
      moment,
      careReceiverForm,
      fileSize,
      displayFile,
      // foundCGI,
      seller,
      saveVisor,
      schema,
      providerUiSchema,
      visorToEdit,
      editModal,
      homeLink,
      resourceUrl,
      contract,
      careStayTableItems,
      householdAddress,
      contractAddress,
      newReservationModal,
      reservedUntil,
      createReservation,
      emergencyPhone
    }
  }
})
