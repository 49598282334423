export const schema = {
  $schema: "https://json-schema.org/draft/2019-09/schema",
  $id: "https://crm.pflegehilfe-senioren.de/visor-model.json",
  type: "object",
  properties: {
    attributes: {
      type: "object",
      title: "Attributes",
      properties: {
        designation: { type: "string", title: "Bezeichnung" }
      }
    }
  }
}

export const providerUiSchema = {
  $schema: "https://json-schema.org/draft/2019-09/schema",
  $id: "https://crm.pflegehilfe-senioren.de/visor-view.json",
  $vocabulary: {
    "https://json-schema.org/draft/2019-09/vocab/core": true,
    "https://crm.pflegehilfe-senioren.de/ui-vocab": true
  },
  "ui:type": "form",
  "ui:widget": "b-row",
  type: "object",
  properties: {
    attributes: {
      type: "object",
      $ref: "https://crm.pflegehilfe-senioren.de/visor-model.json#/properties/attributes",
      "ui:type": "group",
      "ui:label": "",
      properties: {
        designation: {
          $ref: "https://crm.pflegehilfe-senioren.de/visor-model.json#/properties/attributes/properties/designation",
          "ui:label": "Bezeichnung",
          // "ui:type": "checkbox",
          "ui:classNames": ["col-12"]
        },
      }
    }
  }
}