const cancelableDatetime = () => import('@/components/shared/CancelableDateTime.vue')
export const careStaySchema = {
  $schema: "https://json-schema.org/draft/2019-09/schema",
  $id: "https://crm.pflegehilfe-senioren.de/care-stay-model.json",
  type: "object",
  properties: {
    attributes: {
      type: "object",
      title: "Attributes",
      properties: {
        billStart: { type: "string", title: "Rechnungsstart", format: "date" },
        billEnd: { type: "string", title: "Rechnungsende", format: "date" },
        arrival: { type: "string", title: "Anreise", format: "date" },
        departure: { type: "string", title: "Abreise", format: "date" },
        price: { type: "number", title: "Preis"},
        provPfs: { type: "number", title: "Provision PfS", default: "99" },
        provSeller: { type: "number", title: "Provision VP", default: "200" },
        discountPfs: { type: "number", title: "Rabatt Pfs", default: "0" },
        discountSeller: { type: "number", title: "Rabatt VP", default: "0" },
        discountAgency: { type: "number", title: "Rabatt Agentur", default: "0" },
        travelType: { type: "string", title: "Reisetyp" },
        travelCost: { type: "number", title: "Reisekosten" },
        dubHh: { type: "number", title: "2Pers. HH" },
        dubCr: { type: "number", title: "2PB HH" },
        nightCare: { type: "number", title: "Nachtpflege" },
        driversLicense: { type: "number", title: "Führerschein" },
        note: { type: "string", title: "Notiz" },
        availableUntil: { type: "string", title: "Verfübar bis", format: "date" },
        rejectionReason: { type: "string", title: "Grund für die Ablehnung" },
        cancelReason: { type: "string", title: "Grund für den Abbruch" },
      }
    }
  }
}

export const internalUiCareStaySchema = {
  $schema: "https://json-schema.org/draft/2019-09/schema",
  $id: "https://crm.pflegehilfe-senioren.de/care-stay-view.json",
  $vocabulary: {
    "https://json-schema.org/draft/2019-09/vocab/core": true,
    "https://crm.pflegehilfe-senioren.de/ui-vocab": true
  },
  "ui:type": "form",
  "ui:widget": "b-row",
  type: "object",
  properties: {
    attributes: {
      type: "object",
      $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes",
      "ui:type": "group",
      "ui:label": "",
      properties: {
        start: {
          "ui:label": "Rechnungsstart",
          "ui:options": {
            type: 'date',
            inTransform: (val: string) => val.split('T')[0]
          },
          "ui:classNames": ["col-6"],
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/billStart"
        },
        end: {
          "ui:label": "Rechnungsende",
          "ui:options": {
            type: 'date',
            inTransform: (val: string) => val.split('T')[0]
          },
          "ui:classNames": ["col-6"],
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/billEnd"
        },
        arrival: {
          "ui:label": "Anreise",
          "ui:options": {
            type: 'date',
            inTransform: (val: string) => val.split('T')[0]
          },
          "ui:classNames": ["col-6"],
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/arrival"
        },
        departure: {
          "ui:label": "Abreise",
          "ui:options": {
            type: 'date',
            inTransform: (val: string) => val.split('T')[0]
          },
          "ui:classNames": ["col-6"],
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/departure"
        },
        price: {
          "ui:label": "Preis",
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/price",
          "ui:options": { type: "number" },
          "ui:classNames": ["col-6 col-md-4"]
        },
        provPfs: {
          "ui:label": "Provision PfS",
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/provPfs",
          "ui:options": { type: "number" },
          "ui:classNames": ["col-6 col-md-4"]
        },
        provSeller: {
          "ui:label": "Provision VP",
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/provSeller",
          "ui:options": { type: "number" },
          "ui:classNames": ["col-6 col-md-4"]
        },
        discountPfs:
          {
            "ui:label": "Rabatt Pfs",
            $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/discountPfs",
            "ui:options": { type: "number" },
          "ui:classNames": ["col-6 col-md-4"]
          },
        discountSeller:
          {
            "ui:label": "Rabatt VP",
            $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/discountSeller",
            "ui:options": { type: "number" },
          "ui:classNames": ["col-6 col-md-4"]
          },
        discountAgency:
          {
            "ui:label": "Rabatt Agentur",
            $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/discountAgency",
            "ui:options": { type: "number" },
          "ui:classNames": ["col-6 col-md-4"]
          },
        dubHh: {
          "ui:label": "2 Personen HH",
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/dubHh",
          "ui:options": { type: "number" },
          "ui:classNames": ["col-6 col-md-4"]
        },
        dubCr: {
          "ui:label": "2 PB im HH",
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/dubCr",
          "ui:options": { type: "number" },
          "ui:classNames": ["col-6 col-md-4"]
        },
        nightCare: {
          "ui:label": "Nachtpflege",
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/nightCare",
          "ui:options": { type: "number" },
          "ui:classNames": ["col-6 col-md-4"]
        },
        driversLicense: {
          "ui:label": "Führerschein",
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/driversLicense",
          "ui:options": { type: "number" },
          "ui:classNames": ["col-6 col-md-4"]
        },
        travelType: {
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/travelType",
          "ui:classNames": ["col-6 col-md-4"]
        },
        travelCost: {
          "ui:label": "Reisekosten",
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/travelCost",
          "ui:options": { type: "number" },
          "ui:classNames": ["col-6 col-md-4"]
        },
        note: {
          "ui:label": "Notiz",
          "ui:widget": "b-textarea",
          "ui:classNames": ["col-12"],
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/note",
          // "ui:options": { disabled: true }
        },
        availableUntil: {
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/availableUntil",
          "ui:label": "Verfübar bis",
          "ui:options": {
            type: 'date',
            inTransform: (val: string) => val.split('T')[0],
            disabled: true
          },
          "ui:classNames": ["col-6"]
        },
        rejectionReason: {
          "ui:label": "Grund für die Ablehnung",
          "ui:widget": "b-textarea",
          "ui:classNames": ["col-12"],
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/rejectionReason",
          "ui:options": { disabled: true }
        },
        cancelReason: {
          "ui:label": "Grund für den Abbruch",
          "ui:widget": "b-textarea",
          "ui:classNames": ["col-12"],
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/cancelReason",
          "ui:options": { disabled: true }
        }
      }
    }
  }
}

export const sellerUiCareStaySchema = {
  $schema: "https://json-schema.org/draft/2019-09/schema",
  $id: "https://crm.pflegehilfe-senioren.de/care-stay-view.json",
  $vocabulary: {
    "https://json-schema.org/draft/2019-09/vocab/core": true,
    "https://crm.pflegehilfe-senioren.de/ui-vocab": true
  },
  "ui:type": "form",
  "ui:widget": "b-row",
  type: "object",
  properties: {
    attributes: {
      type: "object",
      $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes",
      "ui:type": "group",
      "ui:label": "",
      properties: {
        // start: {
        //   "ui:label": "Rechnungsstart",
        //   "ui:options": {
        //     type: 'date',
        //     inTransform: (val: string) => val.split('T')[0]
        //   },
        //   "ui:classNames": ["col-6"],
        //   $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/billStart"
        // },
        // end: {
        //   "ui:label": "Rechnungsende",
        //   "ui:options": {
        //     type: 'date',
        //     inTransform: (val: string) => val.split('T')[0]
        //   },
        //   "ui:classNames": ["col-6"],
        //   $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/billEnd"
        // },
        arrival: {
          "ui:label": "Anreise",
          "ui:options": {
            type: 'date',
            inTransform: (val: string) => val.split('T')[0],
          },
          "ui:classNames": ["col-6"],
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/arrival"
        },
        departure: {
          "ui:label": "Abreise",
          "ui:options": {
            type: 'date',
            inTransform: (val: string) => val.split('T')[0],
          },
          "ui:classNames": ["col-6"],
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/departure"
        },
        price: {
          "ui:label": "Preis",
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/price",
          "ui:options": {
            type: "number",
            disabled: true
          },
          "ui:classNames": ["col-6 col-md-4"]
        },
        // provPfs: {
        //   "ui:label": "Provision PfS",
        //   $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/provPfs",
        //   "ui:options": { type: "number" },
        //   "ui:classNames": ["col-6 col-md-4"]
        // },
        // provSeller: {
        //   "ui:label": "Provision VP",
        //   $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/provSeller",
        //   "ui:options": { type: "number" },
        //   "ui:classNames": ["col-6 col-md-4"]
        // },
        // discountPfs:
        //   {
        //     "ui:label": "Rabatt Pfs",
        //     $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/discountPfs",
        //     "ui:options": { type: "number" },
        //   "ui:classNames": ["col-6 col-md-4"]
        //   },
        discountSeller:
          {
            "ui:label": "Vertriebsrabatt für diese BK",
            $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/discountSeller",
            "ui:options": { type: "number" },
          "ui:classNames": ["col-6 col-md-4"]
          },
        // discountAgency:
        //   {
        //     "ui:label": "Rabatt Agentur",
        //     $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/discountAgency",
        //     "ui:options": { type: "number" },
        //   "ui:classNames": ["col-6 col-md-4"]
        //   },
        dubHh: {
          "ui:label": "2 Personen HH",
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/dubHh",
          "ui:options": {
            type: "number",
            disabled: true
          },
          "ui:classNames": ["col-6 col-md-4"]
        },
        dubCr: {
          "ui:label": "2 PB im HH",
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/dubCr",
          "ui:options": {
            type: "number",
            disabled: true
          },
          "ui:classNames": ["col-6 col-md-4"]
        },
        nightCare: {
          "ui:label": "Nachtpflege",
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/nightCare",
          "ui:options": {
            type: "number",
            disabled: true
          },
          "ui:classNames": ["col-6 col-md-4"]
        },
        driversLicense: {
          "ui:label": "Führerschein",
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/driversLicense",
          "ui:options": {
            type: "number",
            disabled: true
          },
          "ui:classNames": ["col-6 col-md-4"]
        },
        // travelType: {
        //   $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/travelType",
        //   "ui:classNames": ["col-6 col-md-4"]
        // },
        // travelCost: {
        //   "ui:label": "Reisekosten",
        //   $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/travelCost",
        //   "ui:options": { type: "number" },
        //   "ui:classNames": ["col-6 col-md-4"]
        // },
        note: {
          "ui:label": "Notiz",
          "ui:widget": "b-textarea",
          "ui:classNames": ["col-12"],
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/note",
          // "ui:options": { disabled: true }
        },
        availableUntil: {
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/availableUntil",
          "ui:label": "Verfübar bis",
          "ui:options": {
            type: 'date',
            inTransform: (val: string) => val.split('T')[0],
            disabled: true
          },
          "ui:classNames": ["col-6"]
        },
        rejectionReason: {
          "ui:label": "Grund für die Ablehnung",
          "ui:widget": "b-textarea",
          "ui:classNames": ["col-12"],
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/rejectionReason",
          "ui:options": { disabled: true }
        },
        cancelReason: {
          "ui:label": "Grund für den Abbruch",
          "ui:widget": "b-textarea",
          "ui:classNames": ["col-12"],
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/cancelReason",
          "ui:options": { disabled: true }
        }
      }
    }
  }
}

export const providerUiCareStaySchema = {
  $schema: "https://json-schema.org/draft/2019-09/schema",
  $id: "https://crm.pflegehilfe-senioren.de/care-stay-view.json",
  $vocabulary: {
    "https://json-schema.org/draft/2019-09/vocab/core": true,
    "https://crm.pflegehilfe-senioren.de/ui-vocab": true
  },
  "ui:type": "form",
  "ui:widget": "b-row",
  type: "object",
  properties: {
    attributes: {
      type: "object",
      $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes",
      "ui:type": "group",
      "ui:label": "",
      properties: {
        // billStart: {
        //   "ui:label": "Rechnungsstart",
        //   "ui:options": {
        //     type: 'date',
        //     inTransform: (val: string) => val.split('T')[0],
        //   },
        //   "ui:classNames": ["col-6"],
        //   $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/billStart"
        // },
        billEnd: {
          "ui:label": "Rechnungsende",
          "ui:options": {
            type: 'date',
            inTransform: (val: string) => val.split('T')[0]
          },
          "ui:classNames": ["col-6"],
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/billEnd"
        },
        arrival: {
          "ui:label": "Anreise",
          "ui:options": {
            type: 'date',
            inTransform: (val: string) => val.split('T')[0]
          },
          "ui:classNames": ["col-6"],
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/arrival"
        },
        departure: {
          "ui:label": "Abreise",
          "ui:options": {
            type: 'date',
            inTransform: (val: string) => val.split('T')[0]
          },
          "ui:classNames": ["col-6"],
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/departure"
        },
        price: {
          "ui:label": "Preis",
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/price",
          "ui:options": { type: "number" },
          "ui:classNames": ["col-6 col-md-4"]
        },
        // provPfs: {
        //   "ui:label": "Provision PfS",
        //   $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/provPfs",
        //   "ui:options": { type: "number" },
        //   "ui:classNames": ["col-6 col-md-4"]
        // },
        // provSeller: {
        //   "ui:label": "Provision VP",
        //   $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/provSeller",
        //   "ui:options": { type: "number" },
        //   "ui:classNames": ["col-6 col-md-4"]
        // },
        // discountPfs:
        //   {
        //     "ui:label": "Rabatt Pfs",
        //     $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/discountPfs",
        //     "ui:options": { type: "number" },
        //   "ui:classNames": ["col-6 col-md-4"]
        //   },
        // discountSeller:
        //   {
        //     "ui:label": "Rabatt VP",
        //     $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/discountSeller",
        //     "ui:options": { type: "number" },
        //   "ui:classNames": ["col-6 col-md-4"]
        //   },
        // discountAgency:
        //   {
        //     "ui:label": "Rabatt Agentur",
        //     $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/discountAgency",
        //     "ui:options": { type: "number" },
        //   "ui:classNames": ["col-6 col-md-4"]
        //   },
        dubHh: {
          "ui:label": "2 Personen HH",
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/dubHh",
          "ui:options": { type: "number" },
          "ui:classNames": ["col-6 col-md-4"]
        },
        dubCr: {
          "ui:label": "2 PB im HH",
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/dubCr",
          "ui:options": { type: "number" },
          "ui:classNames": ["col-6 col-md-4"]
        },
        nightCare: {
          "ui:label": "Nachtpflege",
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/nightCare",
          "ui:options": { type: "number" },
          "ui:classNames": ["col-6 col-md-4"]
        },
        driversLicense: {
          "ui:label": "Führerschein",
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/driversLicense",
          "ui:options": { type: "number" },
          "ui:classNames": ["col-6 col-md-4"]
        },
        // travelType: {
        //   $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/travelType",
        //   "ui:classNames": ["col-6 col-md-4"]
        // },
        // travelCost: {
        //   "ui:label": "Reisekosten",
        //   $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/travelCost",
        //   "ui:options": { type: "number" },
        //   "ui:classNames": ["col-6 col-md-4"]
        // },
        note: {
          "ui:label": "Notiz",
          "ui:widget": "b-textarea",
          "ui:classNames": ["col-12"],
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/note",
          // "ui:options": { disabled: true }
        },
        availableUntil: {
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/availableUntil",
          "ui:label": "Verfübar bis",
          "ui:options": {
            type: 'date',
            inTransform: (val: string) => val.split('T')[0],
            disabled: true
          },
          "ui:classNames": ["col-6"]
        },
        rejectionReason: {
          "ui:label": "Grund für die Ablehnung",
          "ui:widget": "b-textarea",
          "ui:classNames": ["col-12"],
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/rejectionReason",
          "ui:options": { disabled: true }
        },
        cancelReason: {
          "ui:label": "Grund für den Abbruch",
          "ui:widget": "b-textarea",
          "ui:classNames": ["col-12"],
          $ref: "https://crm.pflegehilfe-senioren.de/care-stay-model.json#/properties/attributes/properties/cancelReason",
          "ui:options": { disabled: true }
        }
      }
    }
  }
}