import { hmsString } from "@/utils/helper"

export interface Reservation {
  exclusive: boolean,
  cancelled: boolean
  fulfilled: boolean,
  withdrawn: boolean,
  extensionStage: string,
  cancelReason: string,
  cancelComment: string,
  extendUntil: string,
  extensionReason: string,
  extensionComment: string,
  withdrawReason: string,
  withdrawComment: string,
  reservedUntil: string,
}

export const timeLeft = (reservation: any, units: boolean = true) => {
  if (!reservation.id) return 'Bitte Stelle reservieren'
  const ea = Date.parse(reservation.attributes.reservedUntil)
  const tn = new Date().getTime()
  const tl = (ea - tn)/1000
  if (tl < 0) return '0'
  return hmsString(tl, units)
}