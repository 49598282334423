
  import { defineComponent, computed } from "vue"
  import { ResourceObject } from "@/models/jsonapi"
  import { Address } from "@/models/addresses";
  import { fullSalute } from "@/utils/dataExtractors";

  interface Props {
    address: ResourceObject<string, Address>,
    contactType: string,
    url?: string,
    showContacts?: boolean
  }

  export default defineComponent({
    name: 'Address',
    props: {
      address: {
        type: Object,
        required: true
      },
      contactType: {
        type: String,
      },
      url: {
        type: String
      },
      showContacts: {
        type: Boolean,
        default: true
      }
    },
    setup(props: Props) {
      const defaultPhone = computed(() => {
        const phones = props.address.included?.phones
        if (!phones || phones.length === 0) return '--'
        const defaultPhones = phones.filter(p => p.attributes.default)
        return defaultPhones.length > 0 ? defaultPhones[0].attributes.number : phones[0].attributes.number
      })

      const defaultEmail = computed(() => {
        const emails = props.address.included?.emails
        if (!emails || emails.length === 0) return '--'
        const defaultEmails = emails?.filter(e => e.attributes.default)
        return defaultEmails.length > 0 ? defaultEmails[0].attributes.email : emails[0].attributes.email
      })

      const location = (cr) => {
        const zipCode = cr?.zipCode ?? ''
        const city = cr?.city ?? ''
        const location = `${zipCode} ${city}`.trim()
        return (location.length > 0) ? location : '--'
      }

      const street = (cr) => {
        const streetName = cr?.street ?? ''
        const houseNumber = cr?.houseNumber ?? ''
        const street = `${streetName} ${houseNumber}`.trim()
        return (street.length > 0) ? street : '--'
      }

      return {
        defaultPhone,
        defaultEmail,
        street,
        location,
        fullSalute
      }
    }
  })
