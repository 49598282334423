
import { ref, computed, defineComponent } from 'vue'

export default defineComponent({
  props: {
    title: {
      type: String,
      required: false,
    },
    positions: {
      type: Array,
      required: true,
    },
    totalLabel: {
      type: String,
      required: false,
      default: 'Summe',
    },
    unit: {
      type: String,
      required: false,
      default: '€',
    },
  },
  setup(props) {
    const total = computed(() => {
      return props.positions.reduce((acc, curr) => {
        return acc + curr.value
      }, 0)
    })

    return { total }
  },
})
