
  import { defineComponent, ref, computed, watch, reactive, getCurrentInstance } from 'vue'
  import { providerCareStayColumns as careStayColumns } from '@/view_scripts/table_columns/care_stay_columns'
  import { CareStay } from '@/models/care_stays'
  import SchemaForm from '@/components/shared/form/SchemaForm.vue'
  import { careStaySchema, providerUiCareStaySchema as uiCareStaySchema } from '@/view_scripts/form_schemas/care_stay'
  import { getRefreshData } from '@/utils/refreshData'
  import { confirm, prompt } from '@/utils/interactionModals'
  import CareStayPricingInfo from './CareStayPricingInfo.vue'

  export default defineComponent({
    components: {
      SchemaForm,
      CareStayPricingInfo
    },
    props: { items: { type: Array, required: true } },
    setup(props: any) {
      const root = getCurrentInstance().proxy
      const showLoading = ref(false)
      const editCareStayModal = ref()
      const monthlyPriceModal = ref()
      const availableStages = ['Neu', 'Vorgestellt', 'Angenommen', 'Abgebrochen', 'Bestätigt', 'Alle']
      const stage = ref('Alle')
      const filteredCareStays = computed(() => props.items.filter(cs => {
        return (stage.value === 'Alle' || cs.attributes.stage === stage.value)
      }))
      const selectedCareStay = ref()
      const csActionModal = ref()
      const careStayToEdit = ref<ResourceObject<string, CareStay>>()
      const refreshData = getRefreshData()
      const csActionData = reactive<{[key: string]: any}>({
        usecase: null,
        reasons: [],
        wrongstate: false,
        customReason: '',
        selectedReason: null,
        title: 'Default Titel',
        billStart: null,
        billEnd: null,
        arrival: null,
        departure: null,
        action: () => {alert('es wurde keine Aktion festgelegt')}
      })
      const resetCsActionData = () => {
        csActionData.reasons = []
        csActionData.wrongstate = false
        csActionData.customReason = ''
        csActionData.selectedReason = null
        csActionData.title = 'Default Titel'
        csActionData.billStart = null
        csActionData.billEnd = null
        csActionData.arrival = null
        csActionData.departure = null
        csActionData.selectedReason = null
      }

      const showMonthlyPrice = (data: any) => {
        selectedCareStay.value = data.item.attributes
        monthlyPriceModal.value.show()
      }

      const editCareStay = (data: any) => {
        careStayToEdit.value = JSON.parse(JSON.stringify(data.item))
        editCareStayModal.value.show()
      }

      const getReasons = async (sysId: string) => {
        const listQuery = {'filter[sys_id]': sysId, include: 'sublists'}
        showLoading.value = true
        await root.$store.dispatch('lists/load', listQuery)
        const reasons = root.$store.state.lists.data[0].attributes.sublists
          .map((rr)=>{ return {value: rr.id, text: rr.attributes?.name} })
          .concat({value: 'new', text: "Eigenen Grund angeben..."})
        showLoading.value = false
        return reasons
      }

      const confirmCareStay = (data: any) => {
        if (data.item.attributes.stage != 'Angenommen') {
          prompt(root, 'Bitte nur Pflegeeinsätze im Status "Angenommen" bestätigen.')
          return false
        }
        resetCsActionData()
        csActionData.usecase = 'cs_confirm'
        csActionData.title = 'Pflegeeinsatz bestätigen'
        csActionData.billStart = data.item.attributes.billStart.split('T')[0]
        csActionData.billEnd = data.item.attributes.billEnd.split('T')[0]
        csActionData.arrival = data.item.attributes.arrival.split('T')[0]
        csActionData.departure = data.item.attributes.departure.split('T')[0]
        csActionData.action = (careStay: any) => {
          const body = {
            attributes: {
              billStart: csActionData.billStart,
              billEnd: csActionData.billEnd,
              arrival: csActionData.arrival,
              departure: csActionData.departure,
            },
            usecase: csActionData.usecase
          }
          showLoading.value = true
          root.$store.dispatch('care_stays/edit', { id: data.item.id, body: body })
            .then(refreshData)
            .then((r) => showLoading.value = false)
          root.$nextTick(() => {
            csActionModal.value.hide()
          })
        }
        csActionModal.value.show()
      }

      const isCancelReasonSelected = computed(() => {
        return csActionData.usecase === 'cs_cancel' && csActionData.selectedReason !== null
      })

      const cancelCareStay = async (data: any) => {
        resetCsActionData()
        if (data.item.attributes.stage == 'Abgebrochen') {
          prompt(root, 'Dieser Pflegeeinsatz ist bereits abgebrochen.')
          return false
        }
        csActionData.usecase = 'cs_cancel'
        const reasons = await getReasons('suggestion_cancel_reasons')
        reasons.unshift({value: null, text: 'Grund für den Abbruch wählen'})
        csActionData.title = 'Pflegeeinsatz abbrechen'
        csActionData.reasons = reasons
        csActionData.action = (careStay: any) => {
          const body = {
            attributes: data.item.attributes,
            reason: csActionData.selectedReason,
            customReason: csActionData.customReason,
            usecase: csActionData.usecase,
          }
          showLoading.value = true
          root.$store.dispatch('care_stays/edit', { id: data.item.id, body })
            .then(refreshData)
            .finally((r) => showLoading.value = false)
          root.$nextTick(() => { csActionModal.value.hide() })
        }
        csActionModal.value.show()
      }

      const careStayActionColumns = ref(careStayColumns({showMonthlyPrice, editCareStay, confirmCareStay, cancelCareStay}))
      const saveCareStay = async (bvModalEvent: any) => {
        // bvModalEvent.preventDefault()
        // editCareStayModal.value.hide()
        showLoading.value = true
        root.$store.dispatch('care_stays/edit', { id: careStayToEdit.value.id, body: careStayToEdit.value })
          .then(refreshData)
          .then((r) => showLoading.value = false)
        root.$nextTick(() => { csActionModal.value.hide() })
      }
      return {
        showLoading,
        monthlyPriceModal,
        editCareStayModal,
        csActionModal,
        careStaySchema,
        uiCareStaySchema,
        saveCareStay,
        careStayToEdit,
        careStayActionColumns,
        selectedCareStay,
        csActionData,
        filteredCareStays,
        stage,
        availableStages,
        isCancelReasonSelected
      }
    }
  })
