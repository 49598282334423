
import { computed, defineComponent, ref } from 'vue'
import SummationList from '@/components/shared/SummationList.vue'

export default defineComponent({
  components: {
    SummationList,
  },
  props: {
    careStay: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: 'Monatspreis'
    }
  },
  setup(props) {
    const careStay = ref(props.careStay)
    const customRound = (val: number, precision: number = 2): number => Number(val.toFixed(precision))
    const agencyTax = props.careStay?.agencyTax
    const taxFreeProvision = props.careStay?.taxFreeProvision

    const provisionLevels = [
      [200, 100],
      [220, 110],
      [240, 120],
      [260, 130],
      [280, 140],
      [300, 150],
      [320, 160],
      [340, 170],
      [360, 180],
      [380, 190],
      [400, 200],
    ]

    const totalProvision = computed(() => (careStay.value?.provSeller || 0) + (careStay.value?.provPfs || 0))

    const provisionTax = computed(() => {
      const taxableSurcharge = totalProvision.value - (careStay.value?.discountSeller || 0) - taxFreeProvision
      const taxRate = agencyTax / 100
      return taxableSurcharge < 0 ? 0 : customRound(taxableSurcharge * taxRate)
    })

    const legacyDoubleHouseholdSurcharge = computed(() => {
      const v1 = careStay.value?.dubHh || 0
      const v2 = careStay.value?.dubCr || 0
      return customRound(v1 + v2)
    })

    const priceTable = computed(() => {
      return [
        {
          label: 'Agenturpreis',
          value: careStay.value?.price
        },
        {
          label: 'Provision',
          value: totalProvision.value
        },
        {
          label: 'Steuer für extra Provision',
          value: provisionTax.value
        },
        {
          label: 'monatlicher Rabatt',
          value: -careStay.value?.discountSeller || 0,
        },
        {
          label: '2 Pers. Haushalt',
          value: legacyDoubleHouseholdSurcharge.value,
        },
        {
          label: 'Nachtdienst',
          value: careStay.value?.nightCare
        },
        {
          label: 'Führerschein',
          value: careStay.value?.driversLicense
        },
      ]
    })

    return {
      priceTable,
    }
  },
})
