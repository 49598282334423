
  import Vue from 'vue'
  import { defineComponent, ref, computed, watch, getCurrentInstance } from "vue"
  import moment from 'moment'
  import { Visor } from "@/models/visors";
  import { ResourceObject } from '@/models/jsonapi'
  import FormStepper from "@/components/shared/FormStepper.vue"
  //import { Datetime } from 'vue-datetime'
  import SchemaForm from "@/components/shared/form/SchemaForm.vue"
  import Search from "@/components/shared/Search.vue";

  interface Props {
    search: boolean,
    swap: boolean,
    careReceivers: any[],
  }

  export default defineComponent({
    components: {
      FormStepper,
      SchemaForm,
      Search
    },
    props: {
      search: {
        type: Boolean,
        default: false
      },
      swap: {
        type: Boolean,
        default: false
      },
      careReceivers: {
        type: Array,
        default: () => []
      }
    },
    setup(props: Props, { emit }) {
      const root = getCurrentInstance().proxy
      const billAddresses = ref()
      const agencyProfile = computed<any>(() => root.$store.state.agencies.profile)
      watch(agencyProfile, (newValue) => {
        const currentFormValue = form.value
        form.value = {
          ...currentFormValue,
          suggestion: {
            ...currentFormValue.suggestion,
            travelOptions: agencyProfile.value.attributes.travelOptions
          },
        }
      })

      root.$store.dispatch('agencies/get_profile')

      const billAddressQuery = {
          'include': 'emails,phones',
          'fields[emails]': 'all',
          'fields[phones]': 'all',
          'page[size]': -1,
        }
      root.$store.dispatch('bill_addresses/loadv2', billAddressQuery).then(res => {
        const addresses = res.data.data
        const included = res.data.included

        billAddresses.value = addresses.map(address => {
          const addressItm = { ...address, attributes: { ...address.attributes, emails: [], phones: [] } }
          if (addressItm.relationships.emails.data.length) {
            addressItm.attributes.emails = addressItm.relationships.emails.data.map(email => {
              const _email = included.find(itm => itm.id === email.id)
              if (_email) {
                return {
                  ..._email.attributes,
                  id: _email.id
                }
              }
            })
          }

          if (addressItm.relationships.phones.data.length) {
            addressItm.attributes.phones = addressItm.relationships.phones.data.map(phone => {
              const _phone = included.find(itm => itm.id === phone.id)
              if (_phone) {
                return {
                  ..._phone.attributes,
                  id: _phone.id
                }
              }
            })
          }

          return addressItm
        })
      })

      // const defaultReservedUntil = () => {
      //   const d = new Date()
      //   d.setDate(d.getDate()+1)
      //   let newDay = d.getDay()
      //   if (newDay === 0) d.setDate(d.getDate()+1)
      //   if (newDay === 6) d.setDate(d.getDate()+2)
      //   d.setHours(10)
      //   d.setMinutes(0)
      //   d.setSeconds(0)
      //   return d
      // }
      const careGiverFormSchema = {
        $schema: "https://json-schema.org/draft/2019-09/schema",
        $id: "https://crm.pflegehilfe-senioren.de/caregiver-model.json",
        type: "object",
        properties: {
          careGiver: {
            type: "object",
            title: "Care Giver",
            properties: {
              attributes: {
                type: "object",
                title: "Attributes",
                properties: {
                  gender: { type: "string", title: "Geschlecht*", enum: ['f', 'm', 'd'] },
                  title: {
                    type: "array",
                    title: "Titel",
                    items: {
                      type: "string",
                      enum: ['Prof.', 'Dr.']
                    }
                  },
                  firstName: { type: "string", title: "Vorname*" },
                  lastName: { type: "string", title: "Nachname*" },
                  // required: ["gender", "firstName", "lastName"]
                },
              },
            }
          },
          careGiverInstance: {
            type: "object",
            properties: {
              gs: { type: "integer", title: "Deutschkenntnisse*", minimum: 1, maximum: 6 },
              exp: { type: "string", title: "Erfahrung (gesamt)*" },
              expGer: { type: "string", title: "Erfahrung (in Deutschland)" },
              description: { type: "string", title: "Beschreibung" },
              phone: { type: "string", title: "Telefonnummer" },
              birthday: { type: "string", title: "Geburtstag*", format: "date" },
              fileMetaDatas: {
                title: "Dateien (mindestens 1 erforderlich)",
                type: "array",
                items: {
                  type: "object",
                  properties: {
                    id: { type: "string" }
                  }
                }
              },
            },
            required: ["gs", "exp", "expGer", "description", "phone", "birthday", "fileMetaDatas"]
          },
          suggestion: {
            type: "object",
            properties: {
              travelOptions: {
                type: "array",
                title: "Travel Options",
                items: {
                  type: "object",
                  properties: {
                    name: { type: "string", title: "Anreise Art" },
                    cost: { type: "number", title: "Reisekosten" },
                    comment: { type: "string",  title: "Kommentar" }
                  }
                }
              },
              billAddressSysId: { type: "string", title: "Rechnungsadresse" }
            },
            required: ["travelOptions"]
          },
          segment: {
            type: "object",
            properties: {
              start: { type: "string", title: "Rechnungsstart*", format: "date" },
              price: { type: "number", title: "Preis* (ohne Provision)" },
              agStart: { type: "string", title: "Rechnungsstart*", format: "date" },
              cgiArrival: { type: "string", title: "Anreise*", format: "date" },
              cgiDeparture: { type: "string", title: "geplante Abreise* ", format: "date" },
              nightCare: { type: "number" },
              driversLicense: { type: "number" },
              dubHh: { type: "number" },
              dubCr: { type: "number" }
            },
            required: ["start", "price"]
          },
        }
      }

      const optionalNumberInput = () => import('@/components/shared/OptionalNumberInput.vue')
      const addressSelector = () => import('@/components/shared/BillAddressSelector.vue')
      const careGiverUISchema = computed(() => {
        const base: any = {
          $schema: "https://json-schema.org/draft/2019-09/schema",
          $id: "https://crm.pflegehilfe-senioren.de/caregiver-view.json",
          $vocabulary: {
            "https://json-schema.org/draft/2019-09/vocab/core": true,
            "https://crm.pflegehilfe-senioren.de/ui-vocab": true
          },
          "ui:type": "form",
          "ui:widget": "b-row",
          type: "object",
          properties: {
            careGiver: {
              type: "object",
              $ref: "https://crm.pflegehilfe-senioren.de/caregiver-model.json#/properties/careGiver",
              "ui:type": "group",
              "ui:label": "",
              properties: {
                attributes: {
                  type: "object",
                  $ref: "https://crm.pflegehilfe-senioren.de/caregiver-model.json#/properties/careGiver/properties/attributes",
                  "ui:type": "group",
                  "ui:label": "",
                  properties: {
                    gender: {
                      $ref: "https://crm.pflegehilfe-senioren.de/caregiver-model.json#/properties/careGiver/properties/attributes/properties/gender",
                      "ui:label": "",
                      "ui:widget": "b-form-radio-group",
                      "ui:classNames": ["ml-3","mt-4", "col-12"],
                      "ui:options": {
                        options: [
                          {value: "m", text: "Mann"},
                          {value: "f", text: "Frau"},
                          // {value: "d", text: "Divers"},
                        ]
                      },
                    },
                    firstName: {
                      $ref: "https://crm.pflegehilfe-senioren.de/caregiver-model.json#/properties/careGiver/properties/attributes/properties/firstName",
                      "ui:classNames": ["col-sm-6"],
                      "ui:label": "Vorname",
                    },
                    lastName: {
                      $ref: "https://crm.pflegehilfe-senioren.de/caregiver-model.json#/properties/careGiver/properties/attributes/properties/lastName",
                      "ui:classNames": ["col-sm-6"],
                      "ui:label": "Nachname",
                    },
                  }
                }
              }
            },
            segment: {
              type: "object",
              $ref: "https://crm.pflegehilfe-senioren.de/caregiver-model.json#/properties/segment",
              "ui:type": "group",
              "ui:label": "",
              properties: {
                agStart: {
                  $ref: "https://crm.pflegehilfe-senioren.de/caregiver-model.json#/properties/segment/properties/agStart",
                  "ui:label": "Erster Rechnungstag*",
                  "ui:widget": "b-input",
                  "ui:classNames": ["col-sm-3"],
                  "ui:options": {
                    type: "date",
                    inTransform: (val: string) => val.split('T')[0]
                  },
                },
                cgiArrival: {
                  $ref: "https://crm.pflegehilfe-senioren.de/caregiver-model.json#/properties/segment/properties/cgiArrival",
                  "ui:label": "Anreise*",
                  "ui:widget": "b-input",
                  "ui:classNames": ["col-sm-3"],
                  "ui:options": {
                    type: "date",
                    inTransform: (val: string) => val.split('T')[0]
                  },
                },
                cgiDeparture: {
                  $ref: "https://crm.pflegehilfe-senioren.de/caregiver-model.json#/properties/segment/properties/cgiDeparture",
                  "ui:label": "Abreise*",
                  "ui:widget": "b-input",
                  "ui:classNames": ["col-sm-3"],
                  "ui:options": {
                    type: "date",
                    inTransform: (val: string) => val.split('T')[0]
                  },
                },
                price: {
                  $ref: "https://crm.pflegehilfe-senioren.de/caregiver-model.json#/properties/segment/properties/price",
                  "ui:classNames": ["col-sm-3"],
                  "ui:label": "Preis* (ohne Provision)",
                  "ui:options": { type: "number" }
                },
              }
            },
            careGiverInstance: {
              type: "object",
              $ref: "https://crm.pflegehilfe-senioren.de/caregiver-model.json#/properties/careGiverInstance",
              "ui:type": "group",
              "ui:label": "",
              properties: {
                gs: {
                  $ref: "https://crm.pflegehilfe-senioren.de/caregiver-model.json#/properties/careGiverInstance/properties/gs",
                  "ui:label": "Deutschkenntnisse*",
                  "ui:widget": "b-form-radio-group",
                  "ui:options": {
                    options: [
                      { text: "Dk1", value: 1 },
                      { text: "Dk2", value: 2 },
                      { text: "Dk3", value: 3 },
                      { text: "Dk4", value: 4 },
                      { text: "Dk5", value: 5 },
                      { text: "Dk6", value: 6 },
                    ]
                  }
                },
                exp: {
                  $ref: "https://crm.pflegehilfe-senioren.de/caregiver-model.json#/properties/careGiverInstance/properties/exp",
                  "ui:classNames": ["col-sm-6"],
                  "ui:label": "Erfahrung (gesamt)"
                },
                expGer: {
                  $ref: "https://crm.pflegehilfe-senioren.de/caregiver-model.json#/properties/careGiverInstance/properties/expGer",
                  "ui:classNames": ["col-sm-6"],
                  "ui:label": "Erfahrung (in Deutschland)"
                },
                description: {
                  $ref: "https://crm.pflegehilfe-senioren.de/caregiver-model.json#/properties/careGiverInstance/properties/description",
                  "ui:label": "Beschreibung",
                  "ui:widget": "html-element",
                },
                fileMetaDatas: {
                  type: "array",
                  $ref: "https://crm.pflegehilfe-senioren.de/caregiver-model.json#/properties/careGiverInstance/properties/fileMetaDatas",
                  "ui:classNames": ["col-sm-12", "col-md-6"],
                  "ui:type": "collection",
                  "ui:label": "Lebenslauf und Referenzen (mindestens 1 erforderlich)",
                  "ui:widget": "uppyComponent",
                  items: {
                    $ref: "https://crm.pflegehilfe-senioren.de/caregiver-model.json#/properties/careGiverInstance/properties/fileMetaDatas/items",
                    type: "object",
                    properties: {
                      id: {
                        $ref: "https://crm.pflegehilfe-senioren.de/caregiver-model.json#/properties/careGiverInstance/properties/fileMetaDatas/items/properties/id"
                      }
                    }
                  }
                },
                phone: {
                  $ref: "https://crm.pflegehilfe-senioren.de/caregiver-model.json#/properties/careGiverInstance/properties/phone",
                  "ui:classNames": ["col-sm-6", "col-md-3"],
                  "ui:label": "Telefonnummer"
                },
                birthday: {
                  $ref: "https://crm.pflegehilfe-senioren.de/caregiver-model.json#/properties/careGiverInstance/properties/birthday",
                  "ui:classNames": ["col-sm-6", "col-md-3"],
                  "ui:label": "Geburtstag",
                  "ui:widget": "b-input",
                  "ui:options": {
                    type: "date",
                    inTransform: (val: string) => val.split('T')[0]
                  },
                },
              }
            },
            suggestion: {
              type: "object",
              $ref: "https://crm.pflegehilfe-senioren.de/caregiver-model.json#/properties/suggestion",
              "ui:type": "group",
              "ui:label": "",
              properties: {
                travelOptions: {
                  type: "array",
                  $ref: "https://crm.pflegehilfe-senioren.de/caregiver-model.json#/properties/suggestion/properties/travelOptions",
                  "ui:type": "collection",
                  "ui:label": "Reiseoptionen",
                  "ui:options": {
                    removable: false
                  },
                  items: {
                    $ref: "https://crm.pflegehilfe-senioren.de/caregiver-model.json#/properties/suggestion/properties/travelOptions/items",
                    type: "object",
                    "ui:type": "group",
                    properties: {
                      name: {
                        $ref: "https://crm.pflegehilfe-senioren.de/caregiver-model.json#/properties/suggestion/properties/travelOptions/items/properties/name",
                        "ui:label": "Anreise Art",
                        "ui:classNames": ["col-md-6"]
                      },
                      cost: {
                        $ref: "https://crm.pflegehilfe-senioren.de/caregiver-model.json#/properties/suggestion/properties/travelOptions/items/properties/cost",
                        "ui:label": "Reisekosten",
                        "ui:options": { type: "number" },
                        "ui:classNames": ["col-md-6"]
                      },
                      comment: {
                        $ref: "https://crm.pflegehilfe-senioren.de/caregiver-model.json#/properties/suggestion/properties/travelOptions/items/properties/comment",
                        "ui:label": "Kommentar",
                        "ui:widget": "b-form-textarea"
                      }
                    }
                  }
                },
                billAddressSysId: {
                  $ref: 'https://crm.pflegehilfe-senioren.de/caregiver-model.json#/properties/suggestion/properties/billAddressSysId',
                  "ui:label": "Rechnungsadresse",
                  "ui:widget": addressSelector,
                  "ui:options": {
                    addresses: billAddresses.value
                  }
                }
              }
            },
          }
        }
        const nightCare = {
          $ref: "https://crm.pflegehilfe-senioren.de/caregiver-model.json#/properties/segment/properties/nightCare",
          "ui:label": "Nachtzuschlag",
          "ui:classNames": ["col-sm-6", "col-md-4"],
          "ui:widget": optionalNumberInput,
          "ui:options": {
            initialValue: form.value.segment?.nightCare || agencyProfile.value.nightCare,
          }
        }
        const driversLicense = {
          $ref: "https://crm.pflegehilfe-senioren.de/caregiver-model.json#/properties/segment/properties/driversLicense",
          "ui:label": "Führerscheinzuschlag",
          "ui:classNames": ["col-sm-6", "col-md-4"],
          "ui:widget": optionalNumberInput,
          "ui:options": {
            initialValue: form.value.segment?.driversLicense || agencyProfile.value.driversLicense,
          }
        }
        const dubHh = {
          $ref: "https://crm.pflegehilfe-senioren.de/caregiver-model.json#/properties/segment/properties/dubHh",
          "ui:label": "Kostenaufschlag 2 Personen",
          "ui:classNames": ["col-sm-6", "col-md-4"],
          "ui:widget": optionalNumberInput,
          "ui:options": {
            initialValue: form.value.segment?.dubHh || agencyProfile.value.dubHh,
          }
        }
        const dubCr = {
          $ref: "https://crm.pflegehilfe-senioren.de/caregiver-model.json#/properties/segment/properties/dubCr",
          "ui:label": "Kostenaufschlag 2 Pflege Bedürftige",
          "ui:classNames": ["col-sm-6", "col-md-4"],
          "ui:widget": optionalNumberInput,
          "ui:options": {
            initialValue: form.value.segment?.dubCr || agencyProfile.value.dubCr,
          }
        }
        if (props.careReceivers.find(cr => ['gelegentlich', 'täglich'].includes(cr.attributes.nightService))) { base.properties.segment.properties.nightCare = nightCare }
        if (props.careReceivers.find(cr => ['wünschenswert', 'Ja'].includes(cr.attributes.ctDriver))) { base.properties.segment.properties.driversLicense = driversLicense }
        if (props.careReceivers.find(cr => ['Ehepartner', 'Angehöriger', 'Wohngemeinschaft'].includes(cr.attributes.roomMate))) { base.properties.segment.properties.dubHh = dubHh }
        if (props.careReceivers.length > 1) { base.properties.segment.properties.dubCr = dubCr }
        if (!props.swap) { delete base.properties.segment.properties.agStart }
        return base
      })

      const uploadedFiles = ref([])

      const processUppyResult = (result) => {
        uploadedFiles.value = uploadedFiles.value.concat(result.successful.map(suc => suc.response.body.data[0]))
        // console.log({result})
        // const newFileIds = result.successful.map(suc => {
        //   return { id: suc.response.body.data[0].id }
        // })
        // if (newFileIds.length == 0) {alert('Datei Upload gescheitert! Bitte noch einmal versuchen')}
        // if (form.value.careGiverInstance) {
        //   const presentFiles = form.value.careGiverInstance.fileMetaDatas
        //   const oldAndNewFiles = presentFiles ? newFileIds.concat(presentFiles) : newFileIds
        //   const careGiverInstance = {
        //     ...form.value.careGiverInstance,
        //     fileMetaDatas: oldAndNewFiles
        //   }
        //   form.value = {
        //     ...form.value,
        //     careGiverInstance
        //   }
        // } else {
        //   alert('Anhang fehlt! Bitte diese Nummer melden: ' + Date.now().toString())
        // }
        // uploadedFiles.value = result.successful.map(suc => suc.response.body.data[0].attributes.filename+` - ${form.value.careGiverInstance.fileMetaDatas[0].id}`)
        // console.log('formData', form.value)
      }

      const uppy = () => {
        return import('@/components/shared/Uppy.vue')
      }
      const uppyComponent = Vue.component('uppyComponent', {
        render(h) {
          return h(uppy, {
            props: {
              maxNumberOfFiles: null,
            },
            on: {
              complete: processUppyResult
            },
          })
        }
      })

      const form = ref<any>({
        suggestion: {},
        careGiver: {},
        careGiverInstance: {},
        segment: {},
      })

      const formOptions = ref()

      const updatedForm = (formValue) => {
        const newForm = {
          ...form.value,
          ...formValue
        }

        // if (form.value.careGiverInstance.gs !== formValue.gs) {
        //   newForm.segment.price = agencyProfile.value["dk" + formValue.gs]
        // }
        if (formValue.birthday) {
          newForm.careGiverInstance.birthday = formValue.birthday.split('T')[0]
        }

        form.value = newForm
      }

      const sendForm = () => {
        // Change date of birth
        let localForm = { ...form.value }
        if (localForm.careGiverInstance.birthday) {
          localForm.careGiverInstance = {
            ...localForm.careGiverInstance,
            birthday: moment.utc(localForm.careGiverInstance.birthday).toISOString()
          }
        }
        // if (!localForm.careGiverInstance) {
        //   alert('Anhang ist verloren gegangen! Bitte diese Kennung melden: nCGI'+Date.now().toString()+"\nAnhang bitte nachträglich hochladen.")
        // } else if (!localForm.careGiverInstance.fileMetaDatas) {
        //   alert('Anhang ist verloren gegangen! Bitte diese Kennung melden: nFMD'+Date.now().toString()+"\nAnhang bitte nachträglich hochladen.")
        // } else if (localForm.careGiverInstance.fileMetaDatas.length == 0) {
        //   alert('Anhang ist verloren gegangen! Bitte diese Kennung melden: fMDZ'+Date.now().toString()+"\nAnhang bitte nachträglich hochladen.")
        // }
        // if (!form.value.careGiverInstance.fileMetaDatas) {
        //   alert('Anhang ist verloren gegangen! Bitte diese Kennung melden: nFmdFd'+Date.now().toString()+"\nAnhang bitte nachträglich hochladen.")
        // }
        localForm.careGiverInstance.fileMetaDatas = uploadedFiles.value
        emit('submitted', localForm)
      }

      const processSearchResult = async (data: any) => {
        if (data.attributes.file_meta_datas) {
          data.attributes.fileMetaDatas = data.attributes.file_meta_datas
          // deleting this is just a blind attempt to fix a problem. This inconsistency with the underscore key will be resolved soon.
          delete data.attributes.file_meta_datas
        }
        // Check if some file meta data already exist on careGiverInstance
        const fileMetaDatas = data.attributes.fileMetaDatas
        if (fileMetaDatas.length > 0) {
          uploadedFiles.value = fileMetaDatas
          // uploadedFiles.value = fileMetaDatas.map(file => file.attributes.filename)
        }

        const careGiverAttributes = data.attributes.care_giver.attributes
        // delete data.attributes.care_giver
        const newForm = {
          ...form.value,
          careGiverInstance: {
            ...data.attributes
          },
          careGiver: data.attributes.care_giver
        }

        form.value = newForm
        //updatedForm(data.attributes)
        //updatedForm(data.attributes.care_giver)
      }

      return {
        form,
        careGiverFormSchema,
        careGiverUISchema,
        formOptions,
        sendForm,
        updatedForm,
        processSearchResult,
        uploadedFiles,
        agencyProfile
      }
    }
  })
